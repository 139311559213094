<template>
  <Guest>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold text-white uppercase">
        {{ title }}
      </h2>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form>
            <router-view />
        </form>
      </div>
      <div class="mt-6">
        <span @click="goToPreviousStep" v-show="!isFirstStep()" class="inline-block w-5/12 sm:w-3/12 rounded-md shadow-sm">
            <button type="button" class="button-transparent w-full">
                <i18n-t keypath="general.back" />
            </button>
        </span>
        <span @click="goToNextStep" class="inline-block w-5/12 rounded-md shadow-sm float-right">
            <button type="button" class="button w-full">
                <i18n-t keypath="general.next" />
            </button>
        </span>
      </div>
    </div>
  </Guest>
</template>

<script>
import Guest from "@/wrappers/Guest";
import { getWalkthroughQuestions } from "@/helper/api/RegistrationHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
    name: "Register",
    components:{
        Guest,
    },
    computed:{
        title: function(){
            return $t(this.$route.meta.title);
        },
        stepNumber: function(){
            return this.$route.meta.number;
        },
        nextRoute: function(){
            return this.all_step_routes.find(step => step.meta.number === this.stepNumber + 1);
        },
        previousRoute: function(){
            return this.all_step_routes.find(step => step.meta.number === this.stepNumber - 1);
        }
    },
    created(){
        // find all child-routes of 'register.steps':
        this.all_step_routes = this.$router.options.routes.find((route) => route.name == 'register.steps').children;

        window.addEventListener('beforeunload', this.beforeWindowUnload);

        getWalkthroughQuestions()
            .then(data => {
                this.dynamic_questions = data;
                console.log('refreshing questions:' , data);
            })
            .catch(err => {
                console.error(err);
            });
    },
    data(){
        return {
            all_step_routes: [],
            dynamic_questions: [],
        }
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
    methods: {
        goToNextStep: function(){
            if(this.nextRoute){
                this.$router.push({name: this.nextRoute.name})
            } else {
                // No next route; continue with dynamic questions.

                const minimum = (accumulator, currentValue) => Math.min(accumulator, currentValue);
                const getId = (object) => object.id;
                const lowestId = this.dynamic_questions.map(getId).reduce(minimum, Infinity);

                this.$router.push({name: 'register.steps.questions', params: {id: lowestId}});                
            }
        },
        goToPreviousStep: function(){
            if(this.previousRoute){
                this.$router.push({name: this.previousRoute.name})
            }
        },
        isFirstStep: function(){
            return !this.previousRoute;
        },
        confirmLeave() {
            return window.confirm($t('register.confirmLeave'))
        },
        beforeWindowUnload(e) {
            if (!this.confirmLeave()) {
                // Cancel the event
                e.preventDefault()
                // Chrome requires returnValue to be set
                e.returnValue = ''
            }   
        },
    },
    
}
</script>

<style scoped>

</style>
